import { type Injector } from '@angular/core';
import { BaseWidget } from '@sds/widget-gridster';

export class MonthlyEnergyYieldsWidget extends BaseWidget<{ plantId: string; title: string }> {
  static readonly id = 'monthly-energy-yields';
  static readonly publicFriendly = false;

  static factory(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { plantId: string; title: string }
  ) {
    return new MonthlyEnergyYieldsWidget(injector, metaLinkId, x, y, rows, cols, data);
  }

  constructor(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { plantId: string; title: string }
  ) {
    super(
      injector,
      MonthlyEnergyYieldsWidget.id,
      MonthlyEnergyYieldsWidget.publicFriendly,
      metaLinkId,
      x,
      y,
      rows,
      cols,
      data
    );
    this.resolveComponent(async () => {
      const module = await import('../monthly/monthly-energy-yields.component');
      return Object.values(module)[0];
    });
  }

  override resolveData(data: { plantId: string; title: string }): void {
    this.setData({ plantId: data.plantId, title: data.title });
  }
}
